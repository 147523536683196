import Vue from 'vue'
import VueRouter from 'vue-router'



const Home = () => import('../views/mainPage.vue')
const FreeTrial = () => import('../views/freeTrial.vue')
const Success = () => import('../views/success.vue')
const Aboutus = () => import('../views/aboutUs.vue')
const Ivst = () => import('../views/ivst.vue')
const Iegc = () => import('../views/iegc.vue')
const Vtid = () => import('../views/vtid.vue')
const Ivsp = () => import('../views/ivsp.vue')
const Itp = () => import('../views/itp.vue')
const Ivp = () => import('../views/ivp.vue')
const Notfound = () => import('../views/notFound.vue')

const firstPage = ()=>import('@/components/firstPage.vue')
const lastPage = ()=>import('@/components/lastPage.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect:'/firstPage',
    },
    {
        path:'/firstPage',
        component:Home,
        name:'mainPage',
        meta:{
            showheader:true 
        }
    },
    {
        path:'/FreeTrial',
        component:FreeTrial,
        name:'freeTrial',
        meta:{
            showheader:false // 不需要头部导航
        }
    },
    {
        path:'/Success',
        component:Success,
        name:'success',
        meta:{
            showheader:true
        }
    },
    {
        path:'/Aboutus',
        component:Aboutus,
        name:'aboutus',
        meta:{
            showheader:true,
            baisecolor:true,
        }
    },
    {
        path:'/Ivst',
        component:Ivst,
        name:'ivst',
        meta:{
            showheader:true
        } 
    },
    {
        path:'/Iegc',
        component:Iegc,
        name:'iegc',
        meta:{
            showheader:true
        } 
    },
    {
        path:'/Vtid',
        component:Vtid,
        name:'vtid',
        meta:{
            showheader:true
        }  
    },
    {
        path:'/Ivsp',
        component:Ivsp,
        name:'vtid',
        meta:{
            showheader:true
        }  
    },
    {
        path:'/Itp',
        component:Itp,
        name:'itp',
        meta:{
            showheader:true
        }  
    },
    {
        path:'/Ivp',
        component:Ivp,
        name:'ivp',
        meta:{
            showheader:true
        }  
    },
    {
        path:'/404',
        component:Notfound,
        name:'notfound',
        meta:{
            showheader:true
        }
    }
    
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// 路由的导航守卫， 注意最后一定要执行next()，否则页面无法跳转
// router.beforeEach((to, from, next) => {
//     const {path, query, meta = {}} = to
    // 这里只是演示没有token(登录的时候存在了window.sessionStorage中)跳回login页面，还有好多配置可以自己加
    // const token = window.sessionStorage.getItem('token')
    // const header = window.sessionStorage.getItem('token')
    // if(path == '/Aboutus'){
    //     window.sessionStorage.setItem('headerback',true)
        // next({ path: '/Aboutus' })
    //     next()
    // }else{
    //     window.sessionStorage.setItem('headerback',false)
    //     next()
    // }
    // if (!token && path !== '/login') {
    //     next({ path: '/login' })
    // } else {
    //     next()
    // }
// })

export default router
