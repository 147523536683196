<template>
  <div class="header flex space-between alignCenter" :style="{'background':blue}" v-if="ispc">
    <img
      :src="tbimg"
      style="height:50%; cursor:pointer;"
      @click="backToFirstPage"
      class="logoimg"
    />
    <div class="tabs">
      <el-menu 
        router
        :default-active="$route.path" 
        class="el-menu-demo" 
        mode="horizontal" 
        @select="handleSelect"
        :style="{'background':blue}"
      >
        <el-submenu index="1" :style="{'background':blue}">
          <span class="el-dropdown-link" slot="title" :style="{'color':tbcolor}" @mouseover="hoverfc(1)" @mouseout="nohoverfc(1)">
            Products
          </span>
          <div class='proxiala'>
            <div class="left_pro">
              <p class="xialahead">Hardware Products</p>
              <div class="left_content">
                <el-menu-item index="/Ivst">Intelligent Video Sensing terminal</el-menu-item>
                <el-menu-item index="/Iegc">Intelligent Edge Computing Gateway</el-menu-item>
                <el-menu-item index="/Vtid">Video Traffic Incident Detector</el-menu-item>
              </div>
            </div>
            <div class="right_pro">
              <p class="xialahead">Software Products</p>
              <div class="left_content">
                <el-menu-item index="/Ivsp">Intelligent Video Surveillance Platform</el-menu-item>
                <el-menu-item index="/Itp">IoT Platform</el-menu-item>
                <el-menu-item index="/Ivp">IoV Platform</el-menu-item>
              </div>
            </div>
          </div>
        </el-submenu>
        
        <el-submenu index="2" :style="{'background':blue}">
            <span class="el-dropdown-link" slot="title" :style="{'color':tbcolor}" @mouseover="hoverfc(2)" @mouseout="nohoverfc(2)"> 
              Solutions
            </span>
            <div class="solxiala"> 
              <el-menu-item index="">Site Safety Early Warning Solution</el-menu-item>
              <el-menu-item index="">Traffic Condition Solution</el-menu-item>
              <el-menu-item index="">Intelligent Patrol Solution</el-menu-item>
            </div>
        </el-submenu>
        
        <el-menu-item index="" @click="jumpdemo" :style="{'background':blue}">
          <span class="el-dropdown-link" slot="title" :style="{'color':tbcolor}" @mouseover="hoverfc(3)" @mouseout="nohoverfc(3)"> 
            Request Demo
          </span>
        </el-menu-item>

        <el-menu-item index="/Aboutus" :style="{'background':blue}">
          <span class="el-dropdown-link" slot="title" :style="{'color':textcolor}" @mouseover="hoverfc(4)" @mouseout="nohoverfc(4)"> 
            About Us
          </span>
        </el-menu-item>
      </el-menu>
      <div class="header_right" :style="{'background':blue}">
        <p class="el-dropdown-link" style="cursor:pointer;" @click="jumpsign" :style="{'color':tbcolor}" @mouseover="hoverfc(5)" @mouseout="nohoverfc(5)">SIGN IN</p>
        <el-button @click="addfree">Free Trial</el-button>
      </div>
    </div>
  </div>

  <div class="header1" v-else>
    <img
      src="@/assets/img/logo/logoTop1.png"
      style="height:70%; cursor:pointer;"
      @click="backToFirstPage"
      class="logoimg"
    />
    <div class="header_right" :style="{'background':'#0D8AF2'}">
      <p class="el-dropdown-link" style="cursor:pointer;" @click="jumpsign" :style="{'color':'#fff'}" @mouseover="hoverfc(5)" @mouseout="nohoverfc(5)">SIGN IN</p>
      <el-button @click="addfree">Free Trial</el-button>
    </div>
    <el-dropdown placement="bottom-start" :hide-on-click="false">
      <span class="el-dropdown-link">
        ···
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item >
          <!-- Products -->
          <el-popover placement="left-start" trigger="click" v-model="visible">
            <span slot="reference" @click="visible1 = false">Products</span>
            <el-menu
              router
              :default-active="$route.path" 
              class="el-menu-demo" 
              mode="horizontal" 
            > 
              <div class="topnav">
                <p class="xialahead xialahead1">Hardware Products</p>
                <el-menu-item index="/Ivst" @click="showpopver">Intelligent Video Sensing terminal</el-menu-item>
                <el-menu-item index="/Iegc" @click="showpopver">Intelligent Edge Computing Gateway</el-menu-item>
                <el-menu-item index="/Vtid" @click="showpopver">Video Traffic Incident Detector</el-menu-item>
                <div class="btmnav">
                  <p class="xialahead xialahead1">Software Products</p>
                  <el-menu-item index="/Ivsp" @click="showpopver">Intelligent Video Surveillance Platform</el-menu-item>
                  <el-menu-item index="/Itp" @click="showpopver">IoT Platform</el-menu-item>
                  <el-menu-item index="/Ivp" @click="showpopver">IoV Platform</el-menu-item>
                </div>
              </div>
            </el-menu>
          </el-popover>
        </el-dropdown-item>
        <el-dropdown-item>
          <!-- Solutions -->
          <el-popover placement="left-start" trigger="click" v-model="visible1">
            <span slot="reference" @click="visible = false">Solutions</span>
            <el-menu
              router
              :default-active="$route.path" 
              class="el-menu-demo" 
              mode="horizontal" 
            > 
              <div class="topnav topnav1">
                <!-- <p class="xialahead xialahead1">Hardware Products</p> -->
                <el-menu-item index="" @click="showpopver1">Site Safety Early Warning Solution</el-menu-item>
                <el-menu-item index="" @click="showpopver1">Traffic Condition Solution</el-menu-item>
                <el-menu-item index="" @click="showpopver1">Intelligent Patrol Solution</el-menu-item>
              </div>
            </el-menu>
          </el-popover>
        </el-dropdown-item>
        <el-dropdown-item @click.native='jumpdemo()'>Request Demo</el-dropdown-item>
        <el-dropdown-item @click.native='$router.push("/Aboutus")'>About Us</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

export default {
  name:'headers',
  data(){
    return {
      ispc:true,
      visible:false,
      visible1:false,
      blue:'#0D8AF2',
      blue1:'#0D8AF2',
      tbimg:require("@/assets/img/logo/logoTop1.png"),
      tbcolor:'#fff',
      textcolor:'#fff',
      path: this.$route.fullPath,
      currentIndex: 3,
      currentIndex1: 3,
      solindex:3,
      activeIndex: '1',
      activeName: 'first',
      allRoutes:[],
      showheader:true,
      headercolor:false,
      // routerkey: 0,
      leftdata:[
        'Intelligent Video Sensing terminal',
        'Intelligent Edge Computing Gateway',
        'Video Traffic Incident Detector'
      ],
      rightdata:[
        'Intelligent Video Surveillance Platform',
        'IoT Platform',
        'IoV Platform'
      ],
      coludata:[
        'Site Safety Early Warning Solution',
        'Traffic Condition Solution',
        'Intelligent Patrol Solution'
      ]
    }
  },
  created(){
  },
  mounted(){

    let w = document.documentElement.clientWidth || document.body.clientWidth;
    if(w <= 900){
      this.ispc = false
    }else{
      this.ispc = true
    }
    // 刷新之后导航栏颜色
    if(this.path == '/Aboutus' 
      || this.path == '/Ivst' 
      || this.path == '/Iegc' 
      || this.path == '/Vtid' 
      || this.path == '/Ivsp' 
      || this.path == '/Itp'
      || this.path == '/Ivp'
      || this.path == '/success'
      || this.path == '/404'
    ){
      this.blue = '#fff'
      this.tbcolor = '#000'
      this.tbimg = require("@/assets/img/logo/logoTop2.png")
    }else{
      this.blue = '#0D8AF2'
      this.tbcolor = '#fff'
      this.tbimg = require("@/assets/img/logo/logoTop1.png")
    }

    // aboutus 导航栏
    if(this.path == '/firstPage'){
      this.textcolor = '#fff'
    }else if(this.path == '/Ivst' 
      || this.path == '/Iegc' 
      || this.path == '/Vtid' 
      || this.path == '/Ivsp' 
      || this.path == '/Itp' 
      || this.path == '/Ivp' 
      || this.path == '/success' 
      || this.path == '/404'){
      this.textcolor = '#000'
    }else{
      this.textcolor = '#0D8AF2'
    }
  },
  watch:{
    '$route'(to,from){
      console.log(to)
      this.path = to.fullPath;
      this.changeColor()
    }
  },
  methods:{
    showpopver(){
      this.visible1 = false
      this.visible = !this.visible
    },
    showpopver1(){
      this.visible = false
      this.visible1 = !this.visible1
    },
    backToFirstPage() {
      this.$router.push("/firstPage");
    },
    addfree(){
      this.$router.push('/FreeTrial')
    },
    jumpabout(){
      this.$router.push("/Aboutus");

    },
    jumpdemo(){
      window.open("http://125.39.73.102:8003")
    },
    jumpsign(){
      window.open('https://www.chasingai.com:8003/login')
    },
    solClick(index){
      this.solindex = index; 
    },
    handleSelect(key){
      console.log(this.$route,';;;;;;')
    },
    // 字体颜色hover
    hoverfc(i){
      let fontcolor = document.getElementsByClassName('el-dropdown-link');
      if(this.path == '/firstPage'){
        switch (i) {
          case 1 : fontcolor[0].style.color = '#dedede';
          break;
          case 2 : fontcolor[1].style.color = '#dedede';
          break;
          case 3 : fontcolor[2].style.color = '#dedede';
          break;
          case 4 : fontcolor[3].style.color = '#dedede';
          break;
          case 5 : fontcolor[4].style.color = '#dedede';
          break;
        }
      }else{
        switch (i) {
          case 1 : fontcolor[0].style.color = '#0D8AF2';
          break;
          case 2 : fontcolor[1].style.color = '#0D8AF2';
          break;
          case 3 : fontcolor[2].style.color = '#0D8AF2';
          break;
          case 4 : fontcolor[3].style.color = '#0D8AF2';
          break;
          case 5 : fontcolor[4].style.color = '#0D8AF2';
          break;
        }
      }
    },
    nohoverfc(i){
      let fontcolor = document.getElementsByClassName('el-dropdown-link');
      if(this.path == '/firstPage'){
        switch (i) {
          case 1 : fontcolor[0].style.color = '#fff';
          break;
          case 2 : fontcolor[1].style.color = '#fff';
          break;
          case 3 : fontcolor[2].style.color = '#fff';
          break;
          case 4 : fontcolor[3].style.color = '#fff';
          break;
          case 5 : fontcolor[4].style.color = '#fff';
          break;
        }
      }else{
        switch (i) {
          case 1 : fontcolor[0].style.color = '#000';
          break;
          case 2 : fontcolor[1].style.color = '#000';
          break;
          case 3 : fontcolor[2].style.color = '#000';
          break;
          case 4 : this.path == '/Aboutus' ? fontcolor[3].style.color = '#0D8AF2' : fontcolor[3].style.color = '#000';
          break;
          case 5 : fontcolor[4].style.color = '#000';
          break;
        }
      }
    },
    // 改变导航栏颜色
    changeColor(){
      if(this.path == '/Aboutus' 
      || this.path == '/Ivst' 
      || this.path == '/Iegc' 
      || this.path == '/Vtid' 
      || this.path == '/Ivsp'
      || this.path == '/Itp'
      || this.path == '/Ivp'){
        this.blue = '#fff'
        this.tbcolor = '#000'
        this.tbimg = require("@/assets/img/logo/logoTop2.png")
      }else{
        this.blue = '#0D8AF2'
        this.tbcolor = '#fff'
        this.tbimg = require("@/assets/img/logo/logoTop1.png")
      }

      // aboutus
      if(this.path == '/firstPage'){
        this.textcolor = '#fff'
      }else if(this.path == '/Ivst' 
      || this.path == '/Iegc' 
      || this.path == '/Vtid' 
      || this.path == '/Ivsp'
      || this.path == '/Itp'
      || this.path == '/Ivp'){
        this.textcolor = '#000'
      }else{
        this.textcolor = '#0D8AF2'
      }
    }
  }
};
</script>

<style scoped lang="scss">
.header1 {
  width:100%;
  height:1.3rem;
  background:#0D8AF2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  z-index: 999;
  .header_right{
    width:38%;
    height:100px;
    background:#0D8AF2;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-left:140px;
  }

  .header_right p {
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    text-align: center;
    color:#fff;
  }

  .header_right p:hover{
    color:#dedede;
  }

  .header_right .el-button{
    width:290px;
    height:100px;
    padding:0;
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    text-align: center;
    text-transform: uppercase;
    background: #FFA000;
    color:#fff;
    border-radius: 10px;
    border:0;
  }
  .el-dropdown{
    font-size: 150px;
  }
}

.topnav{
  .el-menu-item:nth-child(3){
    margin-bottom:150px;
  }
}

.topnav1{
  .el-menu-item:nth-child(1){
    margin-top:50px;
  }
}

.btmnav{
  padding-top:45px;
  .el-menu-item:nth-child(3){
    margin-top:40px;
    margin-bottom:80px;
  }
}

.xialahead1{
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 900;
  font-size: 55px;
  align-items: center;
  color: #000000;
  // height:10%;
  border-bottom:2px solid #dedede;
  padding-bottom:25px;
  margin-bottom:30px;
}


.el-dropdown-menu__item{
  height:155px;
  line-height:125px;
  font-size: 60px;
  span {
    display: inline-block;
    width:100%;
  }
}

.el-dropdown-menu{
  // width:500px;
  height:600px;
}

.header {
  width: 100%;
  height: 100px;
  padding: 0 117px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #0D8AF2;
  display: flex;
  justify-content: flex-start;
  .tabs{
  width:100%;
  height:60px !important;
  margin-left:50px;
  }

  .tabs .el-menu{
    background:#0D8AF2;
    width:65%;
    border-bottom:0;
    float: left;
  }

  .tabs .el-menu .el-menu-item{
    width:24%;
    border-bottom:0;
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
  }

  .tabs .el-menu .el-menu-item:hover{
    background:#0D8AF2;
    color:#dedede;
  }

  .tabs .el-menu .el-menu-item:hover{
    background:rgba(13,138,242,0) !important;
  }

  .tabs .el-menu .el-menu-item{
    background:rgba(13,138,242,0) !important;
  }

  .header_right{
    width:31%;
    height:60px;
    background:#0D8AF2;
    float: left;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .header_right p {
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    color:#fff;
  }

  .header_right p:hover{
    color:#dedede;
  }

  .header_right .el-button,
  .left_text .el-button{
    width:170px;
    height:40px;
    padding:0;
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    background: #FFA000;
    color:#fff;
    border-radius: 10px;
    border:0;
  }
}

.header_right .el-button:hover{
  background: #FF8A00;
  // color:#dedede;
}

.el-submenu{
  width: 24%;
  /deep/.el-submenu__title{
    border-bottom:0 !important;
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    color:#fff !important;
  }
  /deep/.el-submenu__title:hover{
    background: transparent !important;
  }
}



.el-submenu:hover{
  background: transparent  !important;
}

.id-opened{
  color:#000 !important;
} 


.el-menu-demo{
  display: flex;
  justify-content: space-around;
}

.el-dropdown{
  border-bottom:0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  color:#fff;
}

.el-dropdown span:hover{
  color:#dedede;
}

.proxiala{
  width:660px;
  border-radius: 12px;
  .left_pro{
    width:51%;
    height:250px;
    float:left;
    padding-left:18px;
    padding-right:32px;
    padding-top:10px;
    .xialahead{
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      align-items: center;
      color: #000000;
      height:10%;
      border-bottom:2px solid #dedede;
      padding-bottom:5px;
    }
    .left_content{
      height:90%;
      padding-top:20px;

      .el-menu-item{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        color: #000000;
        margin-bottom:30px;
        cursor: pointer;
        white-space:normal;
        line-height:25px;
        padding-left:0;
      }
    }
  }
  .right_pro{
    width:47%;
    height:250px;
    float:left;
    padding-left:18px;
    // padding-right:5px;
    padding-top:10px;
    .xialahead{
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      align-items: center;
      color: #000000;
      height:10%;
      border-bottom:2px solid #dedede;
      padding-bottom:5px;
    }
    .left_content{
      height:95%;
      padding-top:20px;
      .el-menu-item{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        color: #000000;
        margin-bottom:25px;
        cursor: pointer;
        white-space:normal;
        line-height:25px;
        padding-left:0;
      }
      .el-menu-item:nth-child(2){
        margin-top:35px;
        margin-bottom:15px;
      }
    }
  }
}

/deep/.el-menu-item:hover{
  color:#0D8AF2 !important;
}

.solxiala{
  width:285px;
  border-radius: 12px;
  padding-left:8px;
  padding-top:10px;
  .el-menu-item{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    color: #000000 !important;
    margin-bottom:10px;
    cursor: pointer;
    white-space:normal;
    line-height:25px !important;
    padding-left:0;
  }
  .el-menu-item:hover{
    color:#0D8AF2 !important;
  }
  .el-menu-item:nth-child(2){
    margin-top:40px;
    margin-bottom:30px;
  }
}

.is-opened{
  background:transparent !important;
}

.headerbk{
  background: #fff !important;
}

.blackjack{
  color:#000 !important;
}

.active{
  color:#0D8AF2 !important;
}
.actives{
  color:#0D8AF2 !important;
}


// .el-dropdown-link:hover{
//   color:#dedede !important;
// }

/deep/.el-submenu__icon-arrow{
  display: none;
}

</style>

<style>
.el-menu--popup{
  background:#fff !important;
  padding:10px !important;
  border-radius:10px !important;
}


.el-popover{
  /* height:1500px; */
  width:820px;
  padding:20px !important;
}

.el-popper .el-menu-demo{
  height:100%;
}

.el-popper .el-menu-item{
  width:700px;
  font-size: 60px;
  white-space: normal;
  margin-bottom:140px;
  line-height: 70px;
}

.el-menu.el-menu--horizontal{
  border-bottom:none !important;
}

/* .el-menu-item{
  background: #0D8AF2;
} */

/* .el-menu-item:hover{
  background: #0D8AF2 !important;
} */

.el-menu--popup .el-menu-item{
  background:#fff !important;
  color:#000; 
}


.el-menu .el-menu-item.is-active{
  color:#0D8AF2 !important;
}
</style>