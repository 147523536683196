import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
require('@/assets/style/main.scss');

Vue.use(ElementUI);

Vue.config.productionTip = false

/* vue-bus 兄弟组件传值 */
import VueBus from "vue-bus";
Vue.use(VueBus)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
