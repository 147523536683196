<template>
  <div id="app">
    <headers v-if="$route.meta.showheader"></headers>
    <router-view/>
  </div>
</template>
<script>
import headers from "@/components/header.vue";
export default {
  name: "",
  components:{
    headers,
  }
};
</script>

<style lang="scss">

</style>
